body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #de5f5f;
  --secondary-color: #585353;
  --text-color: #white;
  --bg-color: #c2b8b8;
}

.btn-primary {
  background-color: var(--primary-color);
}

.navbar {
  background-color: var(--bg-color);
}
