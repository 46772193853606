.navbar {
  background-color: #9b7c7c;
  border-radius: auto;
}

.nav__navbar.container {
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.home_box:hover {
  text-decoration: none;
}
.home_box {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills_box {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.me_box {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-size: x-large;
}

a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}
.App {
  text-align: center;
  color: white;
}
#logo {
  background-color: #ff8177;
  background-image: linear-gradient(to top, #3d0e19 0%, #ffb1 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-style: italic;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  background-clip: text;
  color: transparent;
}
.icon {
  color: #343780;
  font-size: 24px;
}
