.footer_wrapper {
  background-color: #9b7c7c;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding: 10px;
  border-radius: 100%;
}

.footer_text {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
