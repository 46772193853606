.skillcol {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
}
.work_box {
  margin: 20px;
}

.sset_box {
  margin: 20px;
}
.skill_list {
  padding: 20px;
}
.cv_icon {
  margin: 6px;
  color: white;
  font-size: 40px;
}

.cv_wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 55px;
}

.cv_text {
  color: white;
}

.cv_text:hover {
  color: #0d6efd;
}
