.image_me {
  border-radius: 100%;
  box-shadow: rgba(160, 154, 154, 0.7) 0px 54px 55px,
    rgba(163, 158, 158, 0.517) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.github_icon {
  margin: 6px;
  color: white;
  font-size: 40px;
}

.github_icon_wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.github_text {
  color: white;
}

.github_text:hover {
  color: #0d6efd;
}
