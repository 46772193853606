.work_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
  justify-content: center;
  font-size: 16px;
  color: white;
}
.title {
  color: white;
  font-size: 28px;
  margin: 10px;
}
.role {
  color: white;
  font-size: 20px;
  margin: 6px;
}
.description {
  color: white;
  font-size: 14px;
  margin: 10px;
}

.duration {
  color: white;
  font-size: 12px;
  margin: 10px;
}
